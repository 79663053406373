import React from 'react'
import ThemeProvider from 'src/theme/ThemeProvider'
import Layout from 'src/theme/Layout'
import {
  MainTitle,
  ArticleContentWrapper,
  Article,
  DownloadFiles
} from 'src/components/static/common'

import PricingUrl from 'src/assets/documents/cennik.pdf'
import PrivacyPolicUrl from 'src/assets/documents/polityka_prywatnosci_i_cookies.pdf'

import DebtCollectionOrderUrl from 'src/assets/documents/zlecenie_windykacji.pdf'

import DebtCollectionAgreementFileUrlPL from 'src/assets/documents/umowa_o_prowadzenie_windykacji.pdf'
import DebtCollectionAgreementFileUrlEN from 'src/assets/documents/umowa_o_prowadzenie_windykacji_en.pdf'

import { useIntl } from 'gatsby-plugin-intl'
import { FormattedMessage } from 'gatsby-plugin-intl'

import TermsOfServiceContentPL from 'src/components/static/termsOfService/TermsOfServiceContentPL'
import TermsOfServiceContentDE from 'src/components/static/termsOfService/TermsOfServiceContentDE'
import TermsOfServiceContentEN from 'src/components/static/termsOfService/TermsOfServiceContentEN'

import PrivacyPolicyAndCookiesFileUrlPL from 'src/assets/documents/privacy_policy_and_cookies/polityka_prywatnosci_i_cookies_pl.pdf'
import PrivacyPolicyAndCookiesFileUrlEN from 'src/assets/documents/privacy_policy_and_cookies/polityka_prywatnosci_i_cookies_en.pdf'
import PrivacyPolicyAndCookiesFileUrlDE from 'src/assets/documents/privacy_policy_and_cookies/polityka_prywatnosci_i_cookies_de.pdf'

import TermsOfServiceFilePL from 'src/assets/documents/Regulamin_e_containers_eu_pl.pdf'
import TermsOfServiceFileDE from 'src/assets/documents/Regulamin_e_containers_eu_de.pdf'
import TermsOfServiceFileEN from 'src/assets/documents/Regulamin_e_containers_eu_en.pdf'

export default function() {
  const intl = useIntl()

  const getLocalizedPrivacyPolicyAndCookiesFileUrl = locale => {
    switch (locale) {
      case 'pl':
        return PrivacyPolicyAndCookiesFileUrlPL
      case 'en':
        return PrivacyPolicyAndCookiesFileUrlEN
      case 'de':
        return PrivacyPolicyAndCookiesFileUrlDE
      default:
        return PrivacyPolicyAndCookiesFileUrlPL
    }
  }

  const getLocalizedTermsOfServiceFileUrl = locale => {
    switch (locale) {
      case 'pl':
        return TermsOfServiceFilePL
      case 'en':
        return TermsOfServiceFileEN
      case 'de':
        return TermsOfServiceFileDE
      default:
        return TermsOfServiceFilePL
    }
  }

  const getLocalizedDebtCollectionAgreementFileUrl = locale => {
    switch (locale) {
      case 'pl':
        return DebtCollectionAgreementFileUrlPL
      case 'en':
        return DebtCollectionAgreementFileUrlEN
      default:
        return DebtCollectionAgreementFileUrlPL
    }
  }
  const renderTermsOfServiceContent = locale => {
    return <br />

    switch (locale) {
      case 'pl':
        return <TermsOfServiceContentPL />
      case 'en':
        return <TermsOfServiceContentEN />
      case 'de':
        return <TermsOfServiceContentDE />
      default:
        return <TermsOfServiceContentPL />
    }
  }

  return (
    <ThemeProvider>
      <Layout>
        <MainTitle>
          <FormattedMessage id='regulations.termsOfService' /> - E-CONTAINERS.EU
        </MainTitle>
        <ArticleContentWrapper>
          <Article>
            <DownloadFiles>
              <span>
                <FormattedMessage id='regulations.documentsToDownload' />:
              </span>
              <ul>
                {/* <li>
                <a href={PricingUrl} target='_blank'>
                  Cennik
                </a>
              </li> */}
                <li>
                  <a
                    href={getLocalizedDebtCollectionAgreementFileUrl(
                      intl.locale
                    )}
                    target='_blank'
                  >
                    <FormattedMessage id='regulations.debtCollectionAgreement' />
                  </a>
                </li>

                <li>
                  <a href={DebtCollectionOrderUrl} target='_blank'>
                    <FormattedMessage id='regulations.debtCollectionOrder' />
                  </a>
                </li>
                <li>
                  <a
                    href={getLocalizedPrivacyPolicyAndCookiesFileUrl(
                      intl.locale
                    )}
                    target='_blank'
                  >
                    <FormattedMessage id='regulations.privacyPolicyAndCookies' />
                  </a>
                </li>
                <li>
                  <a
                    href={getLocalizedTermsOfServiceFileUrl(intl.locale)}
                    target='_blank'
                  >
                    <FormattedMessage id='regulations.termsOfService' />
                  </a>
                </li>
              </ul>
            </DownloadFiles>
            {renderTermsOfServiceContent(intl.locale)}
          </Article>
        </ArticleContentWrapper>
      </Layout>
    </ThemeProvider>
  )
}
